<template>
  <div :class="{ 'disabled-button': stop }">
    <b-button
      size="sm"
      @click="cancel"
    >
      {{ $t('Cancel') }}
    </b-button>

    <form-wizard
      ref="refFormWizard"
      color="#7367F0"
      :title="null"
      :subtitle="null"
      :finish-button-text="$t('Save')"
      :back-button-text="$t('Previous')"
      :next-button-text="$t('Next')"
      class="steps-transparent mb-3 wizard-flat"
      @on-complete="formSubmitted"
    >
      <tab-content
        :title="$t('createUser.PersonalData')"
        :before-change="validationForm"
      >
        <div class="d-flex justify-content-end mb-1">
          <b-button
            size="sm"
            variant="primary"
            @click="formWizardNextStep()"
          >
            {{ $t('Next') }}
          </b-button>
        </div>

        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-card>
            <b-row>
              <!-- last name -->
              <b-col
                md="4"
                lg="2"
              >
                <b-form-group
                  label="ID"
                  label-for="id"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="ID"
                  >
                    <div class="d-flex align-items-center">
                      <b-form-input
                        id="id"
                        v-model="newUserData.userId"
                        :state="errors.length > 0 ? false:null"
                      />

                      <b-button
                        v-b-tooltip.v-primary
                        variant="primary"
                        size="sm"
                        class="ml-50 btn-icon"
                        :title="$t('Generate')"
                        @click="() => { newUserData.userId = genPersonalId() }"
                      >
                        <feather-icon icon="ToolIcon" />
                      </b-button>
                    </div>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                md="4"
                lg="2"
              >
                <b-form-group
                  :label="$t('firstName')"
                  label-for="name"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('firstName')"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="newUserData.firstName"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- last name -->
              <b-col
                md="4"
                lg="2"
              >
                <b-form-group
                  :label="$t('LastName')"
                  label-for="lastName"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('LastName')"
                    rules="required"
                  >
                    <b-form-input
                      id="lastName"
                      v-model="newUserData.lastName"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                md="4"
                lg="6"
              >
                <b-form-group
                  :label="$t('Pesel')"
                  label-for="personalId"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Pesel')"
                  >
                    <b-form-input
                      id="personalId"
                      v-model="newUserData.personalId"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- email -->
              <b-col
                md="6"
                lg="3"
              >
                <b-form-group
                  v-b-tooltip
                  :label="$t('Email')"
                  label-for="email"
                  :title="newUserData.primaryUser ? $t('EmailAddressIsUsedByPrimaryUser') : ''"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Email')"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="newUserData.email"
                      type="email"
                      :state="errors.length > 0 ? false:null"
                      :disabled="newUserData.primaryUser"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- email -->
              <b-col
                md="6"
                lg="3"
              >
                <b-form-group
                  :label="$t('PrivateEmail')"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="newUserData.mailAddressPrivate"
                    type="email"
                  />
                </b-form-group>
              </b-col>
              <!-- phone number -->
              <b-col
                md="6"
                lg="3"
              >
                <b-form-group
                  :label="$t('Phone')"
                  label-for="phone"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('Phone')"
                  >
                    <b-input-group>
                      <b-input-group-prepend>
                        <sw-select>
                          <v-select
                            v-model="countryCode"
                            :options="countryCodes"
                            label="dial_code"
                            style="min-width: 100px"
                            class="mr-50"
                            :clearable="false"
                          >
                            <template #no-options>
                              {{ $t('NoOptions') }}
                            </template>
                          </v-select>
                        </sw-select>
                      </b-input-group-prepend>
                      <cleave
                        id="phone"
                        v-model="newUserData.phone"
                        :options="cleaveOptions.number"
                        class="form-control rounded"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-input-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <!-- phone number -->
              <b-col
                md="6"
                lg="3"
              >
                <b-form-group
                  :label="$t('PrivatePhone')"
                  label-for="phone"
                >
                  <b-input-group>
                    <b-input-group-prepend>
                      <sw-select>
                        <v-select
                          v-model="countryCodePrivate"
                          :options="countryCodes"
                          label="dial_code"
                          style="min-width: 100px"
                          class="mr-50"
                          :clearable="false"
                        >
                          <template #no-options>
                            {{ $t('NoOptions') }}
                          </template>
                        </v-select>
                      </sw-select>
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="newUserData.phoneNumberPrivate"
                      :options="cleaveOptions.number"
                      class="form-control rounded"
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>

              <!-- language -->
              <b-col md="3">
                <sw-select :name="$t('Language')">
                  <v-select
                    id="vi-u-language"
                    v-model="newUserData.locale"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="language"
                    :selectable="option => option.value"
                    :reduce="local => local.value"
                    label="text"
                  >
                    <template #no-options>
                      {{ $t('NoOptions') }}
                    </template>
                  </v-select>
                </sw-select>
              </b-col>
              <b-col
                md="3"
              >
                <sw-select :name="$t('contact.assignedUser')">
                  <assigned-users
                    :value="[...newUserData.parentUser]"
                    :is-multiple="false"
                    :disabled-ids="[...newUserData.childUsers, !isCreateMode ? $route.params.userId : null].filter(Boolean)"
                    @input="changeAssignedUsers"
                  />
                </sw-select>
              </b-col>

              <b-col md="3">
                <b-form-group
                  :label="$t('contact.bankAccountName')"
                  label-for="bankAccountName"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('contact.bankAccountName')"
                  >
                    <b-form-input
                      id="bankAccountName"
                      v-model="newUserData.bankAccountName"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group
                  :label="$t('contact.bankAccountNumber')"
                  label-for="bankAccountNumber"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    :name="$t('contact.bankAccountNumber')"
                  >
                    <b-form-input
                      id="bankAccountNumber"
                      v-model="newUserData.bankAccountNumber"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card>
            <b-row>
              <!-- Group -->
              <b-col
                md="4"
                class="mb-1"
              >
                <sw-select :name="$t('contact.group')">
                  <v-select
                    v-model="newUserData.userGroups"
                    :options="groups"
                    label="name"
                    :reduce="group => group.id"
                    multiple
                  >
                    <template #no-options>
                      {{ $t('NoOptions') }}
                    </template>
                    <template #option="{ name, color }">
                      <div
                        class="color-dot"
                        style="border: 1px solid white"
                        :style="{ backgroundColor: color }"
                      />
                      {{ name }}
                    </template>
                    <template #selected-option="{ name, color }">
                      <div
                        class="color-dot"
                        style="border: 1px solid white"
                        :style="{ backgroundColor: color }"
                      />
                      {{ name }}
                    </template>
                  </v-select>
                </sw-select>
              </b-col>

              <b-col
                sm="12"
              >
                <div>{{ $t('contact.PersonIdentityDocuments') }}
                  <feather-icon
                    v-b-tooltip
                    icon="AlertCircleIcon"
                    class="ml-50"
                    :title="$t('OnlyFirstDocument')"
                  />
                </div>

                <b-row
                  v-for="(doc, index) in newUserData.personIdentityDocuments"
                  :key="`doc${index}`"
                  class="mt-1"
                >
                  <b-col
                    md="6"
                    lg="3"
                    class="mb-50"
                  >
                    <sw-select>
                      <v-select
                        v-model="doc.type"
                        :options="documentTypes"
                        :placeholder="$t('task.Type')"
                        style="min-width: 200px"
                        class="mr-50 mt-25"
                      >
                        <template #selected-option="{ label }">
                          {{ $t(`contact.${label}`) }}
                        </template>
                        <template #option="{ label }">
                          {{ $t(`contact.${label}`) }}
                        </template>
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>
                      </v-select>
                    </sw-select>
                  </b-col>

                  <b-col
                    md="6"
                    lg="3"
                    class="mb-50"
                  >
                    <b-form-input
                      id="basicInput"
                      v-model="doc.number"
                      class="mr-50 mt-25"
                      :placeholder="$t('OrganizationDetails.addressNumber')"
                    />
                  </b-col>

                  <b-col
                    md="6"
                    lg="3"
                    class="mb-50"
                  >
                    <b-form-input
                      id="basicInput"
                      v-model="doc.authorityIssuing"
                      class="mr-50 mt-25"
                      :placeholder="$t('contact.authorityIssuing')"
                    />
                  </b-col>

                  <b-col
                    md="6"
                    lg="3"
                    class="mb-50"
                  >
                    <sw-select>
                      <sw-select-fpr
                        :is-empty="doc.releaseDate"
                        @clear="doc.releaseDate = ''"
                      >
                        <flat-pickr
                          id="releaseDate"
                          v-model="doc.releaseDate"
                          :config="{ locale: getCalendarLocale($i18n.locale) }"
                          class="mr-50 mt-25 form-control"
                          :placeholder="$t('contact.releaseDate')"
                        />
                      </sw-select-fpr>
                    </sw-select>
                  </b-col>

                  <b-col
                    md="6"
                    lg="3"
                    class="mb-50"
                  >

                    <sw-select>
                      <sw-select-fpr
                        :is-empty="doc.expirationDate"
                        @clear="doc.expirationDate = ''"
                      >
                        <flat-pickr
                          id="expirationDate"
                          v-model="doc.expirationDate"
                          :config="{ locale: getCalendarLocale($i18n.locale) }"
                          class="mr-50 mt-25 form-control"
                          :placeholder="$t('contact.expirationDate')"
                        />
                      </sw-select-fpr>
                    </sw-select>
                  </b-col>

                  <b-col
                    md="6"
                    lg="3"
                    class="mb-50 text-right text-md-left"
                  >
                    <b-button
                      variant="outline-danger"
                      class="btn-icon"
                      @click="$delete(newUserData.personIdentityDocuments, index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-col>
                </b-row>

                <b-button
                  variant="primary"
                  size="sm"
                  class="mt-50"
                  @click="newUserData.personIdentityDocuments.push({ type: '', number: '', authorityIssuing: '', releaseDate: '', expirationDate: ''})"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  {{ $t('Add') }}
                </b-button>
              </b-col>

              <b-col
                v-if="checkRequiredViewPermissions([$viewRanks.SHOW_USERS_NOTE])"
                sm="12"
                class="mt-1"
              >
                <b-form-group
                  :label="$t('Note')"
                >
                  <b-form-textarea v-model="newUserData.note" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card>
            <b-row>
              <b-col>
                <b-alert
                  class="d-flex flex-column flex-md-row align-items-center font-weight-bold py-1 p-md-50"
                  variant="primary"
                  show
                >
                  <b-form-checkbox
                    v-model="isCompany"
                    class="custom-control-primary mx-50"
                    switch
                  />
                  <div v-if="isCompany">
                    <div
                      class="d-flex flex-column flex-md-row align-items-center flex-row"
                    >
                      <div class="mr-25 mt-50 mt-md-0">
                        {{ $t('Organization') }}
                      </div>

                      <div class="mr-25 my-50 my-md-0 mx-md-50">
                        <sw-select>
                          <v-select
                            v-model="newUserData.organization"
                            :options="organizations"
                            :placeholder="$t('Select')"
                            label="name"
                            style="min-width: 250px"
                            class="bg-white rounded"
                            @search="reloadOrganizations"
                          >
                            <template #no-options>
                              {{ $t('NoOptions') }}
                            </template>
                          </v-select>
                        </sw-select>
                      </div>
                      <small
                        v-if="!newUserData.organization"
                        class="mb-50 my-md-0 mx-md-50"
                      >
                        {{ $t('Or') }}
                      </small>
                      <div>
                        <b-button
                          v-if="!newUserData.organization"
                          variant="success"
                          class="ml-25"
                          size="sm"
                          @click="addOrganization(false)"
                        >
                          {{ $t('Add') }}
                        </b-button>
                        <b-button
                          v-if="newUserData.organization"
                          variant="warning"
                          class="ml-50"
                          size="sm"
                          @click="addOrganization(true)"
                        >
                          {{ $t('Edit') }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="mr-25 mt-50 mt-md-0">
                      {{ $t('TypeP') }}
                    </div>
                  </div>
                </b-alert>
              </b-col>

              <!-- Natural Person -->
              <b-col
                v-show="!isCompany"
                cols="12"
              >
                <b-tabs>
                  <b-tab :title="$t('PrimaryAddress')">
                    <b-row>
                      <b-col
                        md="6"
                        lg="4"
                      >
                        <b-form-group :label="$t('Country')">
                          <b-form-input
                            id="basicInput"
                            v-model="newUserData.addressCountry"
                            :placeholder="$t('Country')"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        md="6"
                        lg="4"
                      >
                        <b-form-group :label="$t('City')">
                          <b-form-input
                            id="basicInput"
                            v-model="newUserData.addressCity"
                            :placeholder="$t('City')"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col
                        md="6"
                        lg="4"
                      >
                        <b-form-group :label="$t('contact.postalCode')">
                          <cleave
                            id="basicInput"
                            v-model="newUserData.addressPostalCode"
                            :placeholder="$t('contact.postalCode')"
                            :options="cleaveOptions.zip"
                            class="form-control"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="6">
                        <b-form-group :label="$t('Street')">
                          <b-form-input
                            id="basicInput"
                            v-model="newUserData.addressStreet"
                            :placeholder="$t('Street')"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group :label="$t('BuildingNumber')">
                          <b-form-input
                            id="basicInput"
                            v-model="newUserData.addressNumber"
                            :placeholder="$t('BuildingNumber')"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>

                  <!-- Correspondence -->
                  <b-tab :title="$t('AddressForCorrespondence')">
                    <b-button
                      size="sm"
                      variant="primary"
                      style="margin-top: -5px"
                      class="mb-1"
                      @click="copyMainAddress"
                    >
                      {{ $t('Copy') }} (<span class="text-lowercase">{{ $t('PrimaryAddress') }}</span>)
                    </b-button>

                    <b-row>
                      <b-col>
                        <b-form-group :label="$t('Country')">
                          <b-form-input
                            id="basicInput"
                            v-model="newUserData.addressCorrespondenceCountry"
                            :placeholder="$t('Country')"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group :label="$t('City')">
                          <b-form-input
                            id="basicInput"
                            v-model="newUserData.addressCorrespondenceCity"
                            :placeholder="$t('City')"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group :label="$t('contact.postalCode')">
                          <cleave
                            id="basicInput"
                            v-model="newUserData.addressCorrespondencePostalCode"
                            :placeholder="$t('contact.postalCode')"
                            :options="cleaveOptions.zip"
                            class="form-control"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col>
                        <b-form-group :label="$t('Street')">
                          <b-form-input
                            id="basicInput"
                            v-model="newUserData.addressCorrespondenceStreet"
                            :placeholder="$t('Street')"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group :label="$t('BuildingNumber')">
                          <b-form-input
                            id="basicInput"
                            v-model="newUserData.addressCorrespondenceNumber"
                            :placeholder="$t('BuildingNumber')"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-card>
        </validation-observer>
      </tab-content>

      <!-- address  -->
      <tab-content
        :title="$t('createUser.Permissions')"
      >
        <div class="d-flex justify-content-between mb-1">
          <b-button
            size="sm"
            variant="primary"
            @click="formWizardPreviousStep()"
          >
            {{ $t('Previous') }}
          </b-button>

          <b-button
            size="sm"
            variant="primary"
            @click="formWizardNextStep()"
          >
            {{ $t('Next') }}
          </b-button>
        </div>

        <b-card>
          <sw-select :name="$t('PermissionName')">
            <v-select
              ref="rank-select"
              v-model="newUserData.rank"
              :options="ranksList"
              label="name"
              class="mt-25"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-card>
        <rank-details
          v-if="newUserData.rank"
          ref="permissions"
          hide-title
          view-mode
          :rank-id="newUserData.rank.id"
        />
      </tab-content>

      <tab-content
        v-if="checkRequiredModule('marketingModule')"
        :title="$t('MarketingPlan')"
      >
        <div class="d-flex justify-content-between mb-1">
          <b-button
            size="sm"
            variant="primary"
            @click="formWizardPreviousStep()"
          >
            {{ $t('Previous') }}
          </b-button>

          <b-button
            size="sm"
            variant="primary"
            @click="formWizardNextStep()"
          >
            {{ $t('Next') }}
          </b-button>
        </div>

        <b-alert
          show
          class="p-1 d-flex align-items-center"
          variant="primary"
        >
          <feather-icon
            icon="InfoIcon"
            class="mr-50"
          />

          {{ $t('IfYouWantToGiveUsersCommissionForSales') }}
        </b-alert>

        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-card>
            <div>
              <sw-select :name="$t('AssignMarketingPlan')">
                <v-select
                  v-model="newUserData.marketingPlans"
                  class="bg-white"
                  :options="marketingPlans"
                  multiple
                  label="name"
                >
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                </v-select>
              </sw-select>
            </div>

            <div>
              <b-form-checkbox v-model="newUserData.showInUserRanking"
                               variant="primary"
              >
                {{ $t('HideInRanking') }}
              </b-form-checkbox>
            </div>
          </b-card>
          <b-row v-if="newUserData.marketingPlans.length">
            <b-col sm="12">
              <h4>{{ $t('SuperSeller') }}</h4>
            </b-col>
            <b-col v-for="(m, index) in newUserData.marketingPlans"
                   :key="`marketing_plan_${index}`"
                   sm="12"
                   md="3"
            >
              <b-card>
                <div class="d-flex justify-content-between">
                  <strong>{{ m.name }}</strong>
                  <b-form-checkbox v-model="m.superSeller"
                                   switch
                                   variant="primary"
                  />
                </div>
              </b-card>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <tab-content
        v-if="checkRequiredModule('loyaltyModule')"
        :title="$t('LoyaltyPlan')"
      >
        <div class="d-flex justify-content-between mb-1">
          <b-button
            size="sm"
            variant="primary"
            @click="formWizardPreviousStep()"
          >
            {{ $t('Previous') }}
          </b-button>

          <b-button
            size="sm"
            variant="primary"
            @click="formSubmitted()"
          >
            {{ $t('Save') }}
          </b-button>
        </div>

        <b-alert
          show
          class="p-1 d-flex align-items-center"
          variant="primary"
        >
          <feather-icon
            icon="InfoIcon"
            class="mr-50"
          />

          {{ $t('IfYouWantToGiveUsersCommissionForSalesLoyalty') }}
        </b-alert>

        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-card>
            <b-row>
              <sw-select
                class="col-md-6 col-lg-4"
                :name="$t('AssignLoyaltyPlan')"
              >
                <v-select
                  v-model="newUserData.loyaltyPlans"
                  class="bg-white"
                  :options="plans"
                  multiple
                  label="name"
                  :reduce="plan => plan.id"
                >
                  <template #no-options>
                    {{ $t('NoOptions') }}
                  </template>
                </v-select>
              </sw-select>
            </b-row>
          </b-card>
        </validation-observer>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required } from '@validations'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BAlert, BFormTextarea, BTab, BTabs, VBTooltip,
} from 'bootstrap-vue'
import {
  CREATE_USER,
  EDIT_USER,
  GET_GROUPS,
  GET_ORGANIZATIONS,
  GET_SINGLE_USER,
  OPEN_MODAL,
} from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import { countryCodes } from '@/@data/countryCodes'
import flatPickr from 'vue-flatpickr-component'
import { documentTypes } from '@/@data/contact'
import Cleave from 'vue-cleave-component'
import axiosIns from '@/libs/axios'
import RankDetails from '@/views/settings/ranks/RankDetails.vue'
import AssignedUsers from '../../views/components/AssignedUsers.vue'

export default {
  components: {
    BAlert,
    vSelect,
    AssignedUsers,
    FormWizard,
    TabContent,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    RankDetails,
    BTabs,
    BTab,
    flatPickr,
    Cleave,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data(vm) {
    return {
      plans: [],
      marketingPlans: [],

      cleaveOptions: {
        number: {
          delimiter: ' ',
          numeral: true,
          blocks: [3, 3, 3],
        },
        zip: {
          delimiter: '-',
          // numeral: true,
          blocks: [2, 3],
        },
      },
      isCompany: false,
      organizations: [],
      required,
      email,
      documentTypes,
      newUserData: {
        userId: '',
        personalId: '',
        personIdentityDocuments: [],
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        locale: 'pl_pl',
        dealer: false,
        showInUserRanking: false,
        parentUser: [],
        type: 'P',
        companyName: '',
        vatId: 0,
        ncr: '',
        bankAccountNumber: '',
        bankAccountName: '',
        addressStreet: '',
        addressNumber: '',
        addressPostalCode: '',
        addressCity: '',
        addressCountry: '',
        // Correspondence
        addressCorrespondenceStreet: '',
        addressCorrespondenceNumber: '',
        addressCorrespondencePostalCode: '',
        addressCorrespondenceCity: '',
        addressCorrespondenceCountry: '',
        rank: null,
        organization: '',
        note: '',
        userGroups: [],
        phoneNumberPrivate: '',
        mailAddressPrivate: '',
        primaryUser: false,
        loyaltyPlans: [],
        userLoyaltyPlans: [],
        childUsers: [],

        marketingPlans: [],
        userMarketingPlans: [],
      },
      language: [
        { value: 'pl_pl', text: 'Polski' },
        { value: 'en_uk', text: 'Angielski' },
      ],
      typeOptions: [
        { text: vm.$i18n.t('privatePerson'), value: 'P' },
        { text: vm.$i18n.t('company'), value: 'C' },
      ],
      groups: [],
      stop: false,
      countryCode: {
        code: 'PL',
        dial_code: '+48 ',
        name: 'Poland',
      },
      countryCodePrivate: {
        code: 'PL',
        dial_code: '+48 ',
        name: 'Poland',
      },
      countryCodes,
      ranksList: [],
    }
  },
  computed: {
    ...mapGetters({
      reloadContent: 'modal/getReloadContent',
    }),
    locale() {
      return this.$i18n.locale
    },
    isCreateMode() {
      return this.$route.name !== 'edit-user'
    },
  },
  watch: {
    reloadContent(newValue) {
      if (newValue) {
        this.newUserData.organization = this.$store.getters['modal/getModalData']
      }
    },
  },
  /* eslint-disable */
  async mounted() {
    if (this.checkRequiredModule('loyaltyModule')) await this.loadPlans()
    if (this.checkRequiredModule('marketingModule')) await this.loadMarketingPlans()

    this.loadGroups()
    const ranksListResponse = await axiosIns.get('1/ranks', { params: { fields_load: this.$fields.RANKS } })
    this.ranksList = ranksListResponse.data?.data?.items || []

    if (!this.isCreateMode) {
      const { userId } = this.$route.params
      this.$store.dispatch(`users/${GET_SINGLE_USER}`, userId)
        .then(res => {
          Object.keys(res).forEach(key => {
            if (this.newUserData[key] !== undefined && res[key] !== null && key !== 'isOrganization') this.newUserData[key] = res[key]
            if (key === 'isOrganization') this.isCompany = res.isOrganization
            if (key === 'phone' && res[key]) {
              const code = countryCodes.find(({ dial_code }) => res[key].includes(dial_code.trim()))
              if (code) {
                this.newUserData[key] = res[key].replace(code.dial_code.trim(), '')
                this.countryCode = code
              } else {
                this.newUserData[key] = res[key]
              }
            }
            if (key === 'mailAddressPrivate' && res[key]?.mail) {
              this.newUserData[key] = res[key].mail
            }
            if (key === 'phoneNumberPrivate' && res[key]?.phone) {
              const code = countryCodes.find(({ dial_code }) => res[key].phone.includes(dial_code.trim()))
              if (code) {
                this.newUserData[key] = res[key].phone.replace(code.dial_code.trim(), '')
                this.countryCodePrivate = code
              } else {
                this.newUserData[key] = res[key].phone
              }
            }
            if (key === 'personIdentityDocuments') {
              this.$set(this.newUserData, key, res[key])
              this.newUserData[key].forEach(doc => {
                // eslint-disable-next-line
                const expirationDate = typeof doc.expirationDate === 'string' ? doc.expirationDate : doc.expirationDate?.date
                this.$set(doc, 'expirationDate', expirationDate)
                // eslint-disable-next-line
                const releaseDate = typeof doc.releaseDate === 'string' ? doc.releaseDate : doc.releaseDate?.date
                this.$set(doc, 'releaseDate', releaseDate)
              })
            }
            if (key === 'userLoyaltyPlans') {
              this.newUserData[key] = res[key] || []
              this.newUserData.loyaltyPlans = res[key].map(plan => plan.loyaltyPlan.id)
            }

            if (key === 'userMarketingPlans') {
              this.newUserData[key] = res[key].map(e => ({ ...e, name: e.marketingPlan.name })) || []
              this.newUserData.marketingPlans = res[key].map(plan => {
                const exist = res[key].find(a => a.marketingPlan?.id === plan.id)
                return { ...plan.marketingPlan, superSeller: plan?.superSeller || false }
              })
            }
            if (key === 'childUsers') {
              this.newUserData[key] = res[key] || []
              this.newUserData.childUsers = res[key].map(user => user.id)
            }
          })
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    }
  },
  methods: {
    async loadPlans() {
      if (this.checkRequiredModule('loyaltyModule') && this.checkRequiredPermissions([this.$roles.LOYALTY_PLAN_VIEW_ALL])) {
        this.isLoading = true

        try {
          const resp = await axiosIns.get('1/loyaltyPlans', { params: { fields_load: this.$fields.LOYALTY_PLANS } })

          this.plans = resp?.data?.data?.items || []
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        } finally {
          this.isLoading = false
        }
      }
    },
    async loadMarketingPlans() {
      if (this.checkRequiredModule('marketingModule') && this.checkRequiredPermissions([this.$roles.MARKETING_PLAN_VIEW_ALL])) {
        this.isLoading = true

        try {
          const resp = await axiosIns.get('1/marketingPlans', { params: { fields_load: this.$fields.MARKETING_PLANS } })

          this.marketingPlans = resp?.data?.data?.items || []
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        } finally {
          this.isLoading = false
        }
      }
    },
    formWizardPreviousStep() {
      this.$refs.refFormWizard.prevTab()
    },
    formWizardNextStep() {
      this.$refs.refFormWizard.nextTab()
    },
    isFormEnd() {
      return this.$refs.refFormWizard.activeTabIndex + 1 >= this.$refs.refFormWizard.tabs.length
    },
    genPersonalId() {
      const chars = 'abcdefghijklmnopqrstuvwxyz1234567890'
      const rand = (min = 0, max = 1000) => Math.floor(Math.random() * (max - min) + min)
      const randChar = (length = 6) => {
        const randchars = []

        for (let i = 0; i < length; i += 1) {
          randchars.push(chars[rand(0, chars.length)])
        }

        return randchars.join('')
      }

      return randChar()
    },
    copyMainAddress() {
      this.$set(this.newUserData, 'addressCorrespondenceCountry', this.newUserData.addressCountry)
      this.$set(this.newUserData, 'addressCorrespondenceCity', this.newUserData.addressCity)
      this.$set(this.newUserData, 'addressCorrespondencePostalCode', this.newUserData.addressPostalCode)
      this.$set(this.newUserData, 'addressCorrespondenceStreet', this.newUserData.addressStreet)
      this.$set(this.newUserData, 'addressCorrespondenceNumber', this.newUserData.addressNumber)
    },
    loadGroups() {
      this.$store.dispatch(`groups/${GET_GROUPS}`)
        .then(res => {
          this.groups = res.data.items
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    addOrganization(isEdited = false) {
      const payload = {
        modalType: 'organization',
        modalTitle: this.$i18n.t('NewOrganization'),
        okButtonTitle: this.$i18n.t('Save'),
      }
      if (isEdited) {
        payload.data = this.newUserData.organization
        payload.modalTitle = this.$i18n.t('EditOrganization')
      }
      this.$store.commit(`modal/${OPEN_MODAL}`, payload)
    },
    reloadOrganizations(search, loading) {
      if (search.length) {
        loading(true)
        this.$store.dispatch(`organizations/${GET_ORGANIZATIONS}`, search)
          .then(res => {
            this.organizations = res.data.items
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
          .finally(() => {
            loading(false)
          })
      }
    },
    changeAssignedUsers(payload) {
      this.newUserData.parentUser = payload || []
    },
    formSubmitted() {
      this.stop = true
      const {
        firstName,
        lastName,
        phone,
        // eslint-disable-next-line no-shadow
        email,
        locale,
        dealer,
        parentUser,
        bankAccountNumber,
        bankAccountName,
        rank,
        note,
        userGroups,
        personIdentityDocuments,
        personalId,
        phoneNumberPrivate,
        mailAddressPrivate,
        loyaltyPlans,
        userLoyaltyPlans,
        userId,
        marketingPlans,
        userMarketingPlans,
        showInUserRanking,
      } = this.newUserData

      const loyaltyPlansItems = loyaltyPlans.map(planId => {
        const exitstedPlan = userLoyaltyPlans.find(userPlanItem => userPlanItem.loyaltyPlan.id === planId)

        if (exitstedPlan) return { id: exitstedPlan.id, loyaltyPlan: this.getObjectId(exitstedPlan.loyaltyPlan) }

        return { loyaltyPlan: planId }
      })

      const marketingPlansItems = marketingPlans.map(planId => {
        const exitstedPlan = userMarketingPlans.find(userPlanItem => userPlanItem.marketingPlan.id === planId.id)

        if (exitstedPlan) return { id: exitstedPlan.id, marketingPlan: this.getObjectId(exitstedPlan.marketingPlan), superSeller: planId?.superSeller ?? exitstedPlan.marketingPlan?.superSeller ?? exitstedPlan?.superSeller ?? false }

        return { marketingPlan: planId.id, superSeller: planId?.superSeller || false }
      })

      const user = {
        firstName,
        lastName,
        userId,
        showInUserRanking,
        phoneNumberPrivate: phoneNumberPrivate ? this.countryCodePrivate?.dial_code + phoneNumberPrivate : '',
        mailAddressPrivate,
        phoneNumber: phone ? this.countryCode?.dial_code + phone : '',
        mailAddress: email,
        locale,
        dealer,
        parentUser,
        bankAccountNumber,
        bankAccountName,
        rank,
        note,
        userLoyaltyPlans: loyaltyPlansItems,
        userMarketingPlans: marketingPlansItems,
        personIdentityDocuments: personIdentityDocuments.filter(doc => doc.expirationDate && doc.type && doc.number && doc.authorityIssuing && doc.releaseDate),
        personalId: personalId ? String(personalId) : null,
        isOrganization: this.isCompany,
      }

      if (this.isCompany) {
        user.organization = this.newUserData.organization?.id?.toString() || null
      } else {
        const {
          addressCity, addressCountry, addressNumber, addressPostalCode, addressStreet,
          addressCorrespondenceCity, addressCorrespondenceCountry, addressCorrespondenceNumber, addressCorrespondencePostalCode, addressCorrespondenceStreet,
        } = this.newUserData
        user.addressCity = addressCity
        user.addressCountry = addressCountry
        user.addressNumber = addressNumber
        user.addressPostalCode = addressPostalCode ? `${addressPostalCode.toString().substring(0, 2)}-${addressPostalCode.toString().substring(2)}` : addressPostalCode
        user.addressStreet = addressStreet
        user.addressCorrespondenceCity = addressCorrespondenceCity
        user.addressCorrespondenceCountry = addressCorrespondenceCountry
        user.addressCorrespondenceNumber = addressCorrespondenceNumber
        user.addressCorrespondencePostalCode = addressCorrespondencePostalCode ? `${addressCorrespondencePostalCode.toString().substring(0, 2)}-${addressCorrespondencePostalCode.toString().substring(2)}` : addressCorrespondencePostalCode
        user.addressCorrespondenceStreet = addressCorrespondenceStreet
      }

      // Groups
      userGroups.forEach((group, index) => {
        userGroups[index] = group?.id?.toString() || group
      })
      user.userGroups = userGroups

      if (typeof user.parentUser === 'object') {
        user.parentUser = user.parentUser.id
      }

      if (user.parentUser === undefined) {
        user.parentUser = null
      }

      user.rank = this.getObjectId(rank)

      const { isCreateMode } = this
      const action = isCreateMode ? CREATE_USER : EDIT_USER

      if (!isCreateMode) {
        const { userId } = this.$route.params
        user.id = userId
      }

      delete user.canLogin
      delete user.childUsers

      this.$store.dispatch(`users/${action}`, user)
        .then(res => {
          if (!isCreateMode) {
            const userId = res.data.data.items[0].id
            this.$router.push(`/user/${userId}`)
            this.showToast('success', this.$i18n.t('UserUpdated'))
          } else {
            this.showAlert('success', '', this.$i18n.t('UserActivationInfo'), false)
              .then(() => {
                this.$router.push('/users')
                this.showToast('success', this.$i18n.t('UserCreated'))
              })
          }
        })
        .catch(async err => {
          this.stop = false
          // eslint-disable-next-line
          if (err?.response?.data?.message === 'LIMIT_REACHED') {
            this.showAlert('error', '', this.$i18n.t('BuyUsers'), true, this.$i18n.t('GoToSubscriptionPanel'), this.$i18n.t('Close'))
                .then(() => {
                    window.open('https://panel.4b-systems.com/login', '_blank');
                })
            return
          }
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    cancel() {
      if (!this.isCreateMode) {
        const { userId } = this.$route.params
        this.$router.push(`/user/${userId}`)
      } else {
        this.$router.push('/users')
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validationFormType() {
      return new Promise((resolve, reject) => {
        this.$refs.typeRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  .disabled-button {
    .wizard-footer-right * {
      pointer-events: none !important;
    }
  }

  .wizard-flat {
    .wizard-tab-content,
    .wizard-card-footer {
      box-shadow: none !important;
      background: transparent !important;
      padding: 0 !important;
    }
  }
</style>
